import { FieldHookConfig, useField } from 'formik';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import { ControlLabel, FormGroupProps, HelpBlock, Sizes } from 'react-bootstrap';
import FormControl from 'react-bootstrap/lib/FormControl';

type Props = {
  /** true if the input should be auto focused */
  autoFocus?: boolean;
  /** the react-bootstrap size property */
  bsSize?: Sizes;
  /** the form group class name */
  fgClass?: string;
  /** the help text */
  help?: string;
  /** the label to be associated with the input */
  label?: string;
  /** the form name of the input */
  name: string;
  /** true if the value is required as part of the form */
  required?: boolean;
};

function TextInput({
  autoFocus = false,
  bsSize,
  fgClass,
  help,
  label,
  required = true,
  ...props
}: Props & FieldHookConfig<string>) {
  const [field, meta] = useField(props);

  const validationState =
    meta.touched && meta.error ? 'error' : meta.touched && required ? 'success' : null;
  const fgprops: FormGroupProps = {
    ...(!!bsSize && { bsSize }),
    ...(fgClass && { className: fgClass }),
    controlId: field.name,
    validationState
  };
  const disabled = props.disabled ? props.disabled : undefined;
  const placeholder = props.placeholder ? props.placeholder : '';
  const type = props.type ? props.type : 'text';

  return (
    <FormGroup {...fgprops}>
      {!!label && <ControlLabel>{label}</ControlLabel>}
      <FormControl
        autoFocus={autoFocus}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        {...field}
      />
      {(!meta.touched || !meta.error) && help && <HelpBlock>{help}</HelpBlock>}
      {meta.touched && meta.error && <HelpBlock>{meta.error}</HelpBlock>}
    </FormGroup>
  );
}

export default TextInput;
