import { useFormikContext, Form } from 'formik';
import Button from 'react-bootstrap/lib/Button';
import TextInput from '../../../shared/Form/TextInput';

function CustomerForm() {
  // State
  const { dirty, isValid } = useFormikContext();

  return (
    <Form>
      <TextInput
        name="name"
        help="Please enter your company's name"
        label="Company Name"
        required
      />
      <Button bsStyle="primary" disabled={!dirty || (dirty && !isValid)} type="submit">
        Create Customer
      </Button>
    </Form>
  );
}

export default CustomerForm;
