import { useCallback } from 'react';
import { ApolloError, gql, useMutation } from '@apollo/client';
import { CustomerModalOutput, CustomerType } from '../../types';

type UseUpdateCustomerType = {
  /** the response from the mutate function */
  data?: CustomerType;
  /** any errors produced by Apollo */
  error?: ApolloError;
  /** true if the server is processing the request */
  loading: boolean;
  /** the function to call to initiate the update process */
  mutate: (customer: CustomerModalOutput) => Promise<any>;
  /** the function to call to toggle the "active" flag on the customer */
  toggle: (id: string, active: boolean) => Promise<any>;
};

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($id: ID!, $fields: UpdateCustomerFieldsInput) {
    updateCustomer(input: { id: $id, fields: $fields }) {
      clientMutationId
      customer {
        active
        groups {
          ... on Element {
            value
          }
        }
        id
        name
        objectId
        portal_renewal
      }
    }
  }
`;

function useUpdateCustomer(): UseUpdateCustomerType {
  const [mutate, { data, error, loading }] = useMutation(UPDATE_CUSTOMER);

  const editCustomer = useCallback(
    (customer: CustomerModalOutput) => {
      return mutate({
        variables: {
          id: customer.id,
          fields: {
            active: customer.active,
            groups: customer.groups,
            licenses: customer.licenses,
            name: customer.name,
            ...(customer.parent ? { parent: { link: customer.parent } } : {}),
            ...(customer.portal_renewal ? { portal_renewal: customer.portal_renewal } : {})
          }
        }
      });
    },
    [mutate]
  );

  const toggleCustomer = useCallback(
    (id: string, active: boolean) => {
      return mutate({ variables: { id, fields: { active } } });
    },
    [mutate]
  );

  return { data, error, loading, mutate: editCustomer, toggle: toggleCustomer };
}

export default useUpdateCustomer;
