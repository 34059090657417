import { useCallback } from 'react';
import { Auth } from 'aws-amplify';
import { gql, useMutation } from '@apollo/client';

export const RESET_USER_PASSWORD = gql`
  mutation ResetUserPassword($email: String!) {
    resetPassword(input: { email: $email }) {
      clientMutationId
      ok
    }
  }
`;

export type ResetPasswordType = {
  /** the function to call to initiate the password reset flow */
  mutate: (username: string) => Promise<any>;
};

/**
 * This function will allow a user to reset their password.
 */
export function useResetPassword(): ResetPasswordType {
  const [mutate] = useMutation(RESET_USER_PASSWORD);

  const handlePasswordReset = useCallback(
    (username) => {
      return Auth.forgotPassword(username).catch((err) => {
        if (err.code === 'UserNotFoundException') {
          return mutate({ variables: { email: username } });
        }
        throw err;
      });
    },
    [mutate]
  );

  return { mutate: handlePasswordReset };
}
