import { Formik } from 'formik';
import Alert from 'react-bootstrap/lib/Alert';
import Col from 'react-bootstrap/lib/Col';
import Grid from 'react-bootstrap/lib/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useCreateCustomer } from '../../../shared/hooks/customers';
import CustomerForm from './CustomerForm';

type Props = {
  /** the child elements to display within the wrapper */
  children: any;
  /** true if the user is currently associated with a customer */
  hasCustomer: boolean;
  /** the path to redirect the user to upon successful customer creation */
  routeOnSuccess: string;
};

type InitialValuesType = {
  active: boolean;
  groups: string;
  licenses: number;
  name: string;
};

const initialValues: InitialValuesType = {
  active: false,
  groups: '',
  licenses: 0,
  name: ''
};

const schema = Yup.object().shape({
  active: Yup.bool(),
  groups: Yup.string(),
  licenses: Yup.number().required(),
  name: Yup.string().required()
});

function EnforceCustomerWrapper({ children, hasCustomer, routeOnSuccess }: Props) {
  const history = useHistory();
  const { mutate } = useCreateCustomer();

  const handleSubmit = (values: InitialValuesType) => {
    const rtnval = {
      ...values,
      groups: (values.groups ? values.groups.split(',') : []).map((v) => v.trim())
    };
    mutate(rtnval).then(() => {
      history.replace({
        pathname: routeOnSuccess,
        state: {
          toast: {
            message: 'You have successfully completed your new account',
            options: {
              type: 'success'
            }
          }
        }
      });
    });
  };

  return hasCustomer ? (
    children
  ) : (
    <Grid style={{ padding: '3rem' }} data-testid="mozaicid-customer">
      <Col md={6} mdOffset={3}>
        <Alert bsStyle="warning">
          <h4>
            {' '}
            <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '15px' }} /> Sorry!
          </h4>
          <p>It looks like we need a bit more information from you.</p>
        </Alert>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
          <CustomerForm />
        </Formik>
      </Col>
    </Grid>
  );
}

export default EnforceCustomerWrapper;
