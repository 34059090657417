import { useCallback, useEffect, useReducer, useRef } from 'react';
import { Auth } from 'aws-amplify';
import { MFAType } from '../../../components/Login/types';
import * as Parse from 'parse';

export type UserProfileType = {
  /** the user's email address */
  email: string;
  /** an error message that may have occurred when fetching the profile */
  error?: string;
  /** the user's first (or given) name */
  firstName: string;
  /** the Parse profile ID */
  id?: string;
  /** the user's last (or family) name */
  lastName: string;
  /** true if the user profile is being fetched */
  loading?: boolean;
  /** the multi-factor authentication type */
  mfaType?: MFAType;
  /** the Cognito user (if available) */
  user: any;
  /** the Pares User ID */
  userId?: string;
};

export type UserProfileModifiers = {
  /** this will update the user's profile within our internal state */
  updateProfile: (firstName: string, lastName: string) => void;
};

type Action =
  | { type: 'setCognitoProfile'; user: any }
  | { type: 'setError'; error: string }
  | { type: 'setLoading'; loading: boolean }
  | { type: 'setMFAType'; mfaType: MFAType }
  | { type: 'setProfile'; profile: UserProfileType }
  | { type: 'updateProfile'; firstName: string; lastName: string };

function reducer(state: UserProfileType, action: Action): UserProfileType {
  switch (action.type) {
    case 'setCognitoProfile':
      return {
        ...state,
        email: action.user.attributes.email,
        firstName: action.user.attributes.given_name,
        lastName: action.user.attributes.family_name,
        loading: false,
        user: action.user
      };
    case 'setError':
      return {
        ...state,
        error: action.error
      };
    case 'setLoading':
      return {
        ...state,
        loading: action.loading
      };
    case 'setMFAType':
      return {
        ...state,
        mfaType: action.mfaType
      };
    case 'setProfile':
      return action.profile;
    case 'updateProfile':
      return {
        ...state,
        firstName: action.firstName,
        lastName: action.lastName
      };
    default:
      return state;
  }
}

function initialize(): UserProfileType {
  return {
    email: '',
    firstName: 'User',
    lastName: 'Name',
    loading: false,
    mfaType: undefined,
    user: null
  };
}

/**
 * This function will either pull the user's profile from the Parse backend or from Cognito.
 */
export function useMyProfile(): UserProfileType & UserProfileModifiers {
  const mounted = useRef(true);
  const [state, dispatch] = useReducer(reducer, {}, initialize);

  useEffect(() => {
    mounted.current = true;
    dispatch({ type: 'setLoading', loading: true });
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then((user) => {
        dispatch({ type: 'setCognitoProfile', user });
        return user;
      })
      .then((user) => Auth.getPreferredMFA(user))
      .then((mfaType) => {
        const realMFA: MFAType = mfaType !== 'NOMFA' ? (mfaType as MFAType) : null;
        dispatch({ type: 'setMFAType', mfaType: realMFA });
      })
      .catch(() => {
        const user = Parse.User.current();
        if (user && mounted.current) {
          dispatch({
            type: 'setProfile',
            profile: {
              email: user.get('email'),
              firstName: user.get('first_name'),
              id: user.id,
              lastName: user.get('last_name'),
              loading: false,
              user: null
            }
          });
        }
      });

    return () => {
      mounted.current = false;
    };
  }, []);

  const updateProfile = useCallback((firstName: string, lastName: string) => {
    dispatch({ type: 'updateProfile', firstName, lastName });
  }, []);

  return { ...state, updateProfile };
}
