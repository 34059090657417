import { useState } from 'react';
import * as Yup from 'yup';
import { Auth } from 'aws-amplify';
import { Form, Formik } from 'formik';
import Alert from 'react-bootstrap/lib/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import TextInput from '../../../shared/Form/TextInput';
import { LoginSuccessFunc, MFAType } from '../types';
import LoadingButton from '../../../shared/Buttons/Loading';

type Props = {
  /** the user's email address */
  email: string;
  /** the multi-factor authentication type (SMS or software token) */
  mfaType: MFAType;
  /** the function to call when the validation is successful */
  onSuccess: LoginSuccessFunc;
  /** the Cognito user that requires the extra authentication */
  user: any;
};

function TokenAuthForm({ email, mfaType, onSuccess, user }: Props) {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues = { code: '' };
  const schema = Yup.object().shape({
    code: Yup.string().required('Please enter your MFA token')
  });

  const handleSubmit = ({ code }: any) => {
    setLoading(true);
    Auth.confirmSignIn(user, code, mfaType)
      .then((response) => {
        const attributes = response.attributes || response.signInUserSession.idToken.payload;
        return onSuccess(
          response.username,
          response.signInUserSession.accessToken.jwtToken,
          email,
          attributes.given_name,
          attributes.family_name
        );
      })
      .catch((err) => {
        setErrorMessage(err.message);
        setLoading(false);
      });
  };

  return (
    <Formik
      className="form-auth-small"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      <Form>
        {!errorMessage && (
          <Alert bsStyle="info">Please enter your two-factor authentication token</Alert>
        )}
        {errorMessage && (
          <Alert bsStyle="danger" data-testid="login-message">
            <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '15px' }} />
            {errorMessage}
          </Alert>
        )}
        <TextInput
          autoFocus
          name="code"
          type="text"
          autoComplete="off"
          placeholder="Enter token value"
        />
        <div style={{ marginTop: '20px' }}>
          <LoadingButton block bsSize="large" disabled={loading}>
            LOGIN
          </LoadingButton>
        </div>
      </Form>
    </Formik>
  );
}

export default TokenAuthForm;
