import { lazy, useEffect, Suspense } from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { toast, Slide, ToastContainer, TypeOptions } from 'react-toastify';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import ProfileContext from '../../shared/contexts/ProfileContext';
import AppIsBusyWrapper from './AppIsBusyWrapper';
import DisplayErrorsWrapper from './DisplayErrorsWrapper';
import EnforceCustomerWrapper from './EnforceCustomerWrapper';
import { useMyProfile, useMyRoles } from '../../shared/hooks/profiles';
import Loader from '../../shared/Loader';
import 'react-toastify/dist/ReactToastify.css';
import styles from './Main.module.css';
import PrivacyPolicy from '../Support/Privacy';
import TermsOfService from '../Support/Terms';

const Dashboard = lazy(() => import('../Dashboard'));
const Contact = lazy(() => import('../Contact'));
const Customers = lazy(() => import('../Customers'));
const CustomerDetail = lazy(() => import('../CustomerDetail'));
const Locations = lazy(() => import('../Locations'));
const Notifications = lazy(() => import('../Notifications'));
const Users = lazy(() => import('../Users'));
const Devices = lazy(() => import('../Devices'));
const DeviceDetail = lazy(() => import('../DeviceDetail'));
const Uploads = lazy(() => import('../Uploads'));
const Support = lazy(() => import('../Support'));
const Lists = lazy(() => import('../Lists'));
const Profile = lazy(() => import('../Profile'));

type LocationStateType = {
  toast?: {
    message: string;
    options: {
      type: TypeOptions;
    };
  };
};

function Main() {
  const history = useHistory<LocationStateType>();
  const { error: profileError, loading: profileLoading, ...myProfile } = useMyProfile();
  const { customerId, error: rolesError, loading: rolesLoading, ...roles } = useMyRoles();
  const loading = profileLoading || rolesLoading;
  const profile = {
    ...myProfile,
    customerAdmin: roles.customerAdmin,
    fullAdmin: roles.fullAdmin,
    roles: roles.roles
  };
  const errors = [...(profileError ? [profileError] : []), ...(rolesError ? [rolesError] : [])];

  // A universal way to send an alert on transition
  useEffect(() => {
    if (history.location.state?.toast) {
      toast(history.location.state.toast.message, history.location.state.toast.options || {});

      // Remove the success flag
      const { state, ...loc } = history.location;
      const { toast: toastNoop, ...locState } = state || {};
      history.replace({
        ...loc,
        ...(Object.keys(locState).length > 0 ? { state: locState } : {})
      });
    }
  }, [history]);

  return (
    <ProfileContext.Provider value={{ customerId, ...profile }}>
      <div id="wrapper">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          transition={Slide}
        />
        <Header />
        {!loading && <Sidebar />}
        <div className="main" data-testid="mozaicid-main">
          <div className="main-content" style={{ minHeight: '1000px', position: 'relative' }}>
            <AppIsBusyWrapper isBusy={loading} Loader={Loader}>
              <DisplayErrorsWrapper className={styles.ErrorPage} errors={errors}>
                <EnforceCustomerWrapper
                  hasCustomer={profile.fullAdmin || !!customerId}
                  routeOnSuccess="/dashboard"
                >
                  <Suspense fallback={<Loader />}>
                    <Switch>
                      <Route component={Dashboard} path="/dashboard/:id?" />
                      <Route component={CustomerDetail} path="/customers/:id" exact />
                      <Route
                        path="/customers"
                        render={() => <Customers customerId={customerId} />}
                      />
                      <Route path="/locations" render={() => <Locations />} />
                      <Route path="/profile" render={() => <Profile />} />
                      <Route path="/notifications" render={() => <Notifications />} />
                      <Route path="/users" render={() => <Users customerId={customerId} />} />
                      <Route component={DeviceDetail} path="/devices/:id" exact />
                      <Route path="/devices" render={() => <Devices />} />
                      <Route path="/uploads" render={() => <Uploads customerId={customerId} />} />
                      <Route component={Support} path="/support" exact />
                      <Route component={PrivacyPolicy} path="/support/privacy" exact />
                      <Route component={TermsOfService} path="/support/terms" exact />
                      <Route component={Lists} path="/lists" />
                      <Route component={Contact} path="/contact" />
                      <Redirect to="/dashboard" />
                    </Switch>
                  </Suspense>
                </EnforceCustomerWrapper>
              </DisplayErrorsWrapper>
            </AppIsBusyWrapper>
          </div>
        </div>
        <div className="clearfix" />
        <footer>
          <div className=" container-fluid">
            <p className=" copyright">
              &copy; 2021 Mozaic Inc. All Rights Reserved. |{' '}
              <Link to="/support/privacy">Privacy Policy</Link> |{' '}
              <Link to="/support/terms">Terms of Service</Link>
            </p>
          </div>
        </footer>
      </div>
    </ProfileContext.Provider>
  );
}

export default Main;
