import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { LoginPayload } from './useLogin';

export type LegacyLoginType = {
  /** true if the login process is active */
  loading: boolean;
  /** the function to call to initiate the login process */
  mutate: (username: string, password: string) => Promise<LoginPayload>;
};

export const PERFORM_LEGACY_LOGIN = gql`
  mutation PerformLegacyLogIn($username: String!, $password: String!) {
    logIn(input: { username: $username, password: $password }) {
      viewer {
        sessionToken
        user {
          email
          id
          username
        }
      }
    }
  }
`;

/**
 * This function will attempt to log the user in. On a successful login, we'll return the user
 * object and the new session token.
 */
export function useLegacyLogin(): LegacyLoginType {
  const [mutate, { loading }] = useMutation(PERFORM_LEGACY_LOGIN);

  const handleLogin = useCallback(
    (username, password) => {
      return mutate({ variables: { username, password } }).then(({ data }) =>
        data && data.logIn
          ? {
              sessionToken: data.logIn.viewer.sessionToken,
              user: {
                ...data.logIn.viewer.user,
                isLegacy: true
              }
            }
          : Promise.reject('invalid user')
      );
    },
    [mutate]
  );

  return { loading, mutate: handleLogin };
}
