import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-light-svg-icons';
import { useResetPassword } from '../../../shared/hooks/profiles';
import styles from '../Login.module.css';

type Props = {
  /** the function to call when an error is detected */
  onError: (message: string) => void;
  /** the function to call when the password reset email was sent successfully */
  onSuccess: () => void;
  /** the username/email of the user that needs their password reset */
  username?: string;
};

function ResetPassword({ onError, onSuccess, username }: Props) {
  const { mutate } = useResetPassword();

  const handleClick = () => {
    if (username) {
      mutate(username)
        .then(onSuccess)
        .catch((err) => onError(err.message));
    } else {
      onError('Please enter your email address first');
    }
  };

  return (
    <span>
      <FontAwesomeIcon icon={faLock} />
      <span className={styles.formLink} onClick={handleClick}>
        Forgot password?
      </span>
    </span>
  );
}

export default ResetPassword;
