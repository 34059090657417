import { useCallback } from 'react';
import { ApolloError, gql, useMutation } from '@apollo/client';
import { LocationType } from '../../types';

type UseDeleteInvitationType = {
  /** the updated invitation details */
  data?: LocationType;
  /** the error thrown from trying to create the invitation */
  error?: ApolloError;
  /** true if the server is processing the request */
  loading: boolean;
  /** the function to call to initiate the delete */
  mutate: (itemId: string) => Promise<any>;
};

export const DELETE_INVITATION = gql`
  mutation DeleteInvitation($id: ID!) {
    deleteInvitation(input: { id: $id }) {
      clientMutationId
      invitation {
        objectId
        recipient
        sender
      }
    }
  }
`;

function useDeletInvitation(): UseDeleteInvitationType {
  const [mutate, { data, error, loading }] = useMutation(DELETE_INVITATION);

  const deleteInvitation = useCallback(
    (itemId: string) => {
      return mutate({ variables: { id: itemId } });
    },
    [mutate]
  );

  return { data, error, loading, mutate: deleteInvitation };
}

export default useDeletInvitation;
