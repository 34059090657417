import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_ALL_CONTRACTORS } from './useCustomers';
import { CustomerType } from '../../types';

type UseContractorsType = {
  /** the list of customers returned from the query */
  contractors: CustomerType[];
  /** true if the server is processing the request */
  loading: boolean;
  /** the function to call to refetch the query */
  refetch: () => Promise<any>;
};

/**
 * This hook will fetch all of the top-level customers or all of the contractors under a specific
 * customer.
 *
 * @param customerId - the customer ID to filter the results on
 */
function useCustomers(customerId?: string): UseContractorsType {
  const [contractors, setContractors] = useState([]);
  const variables = { customerId, sort: ['name_ASC'] };
  const { data, loading, refetch } = useQuery(GET_ALL_CONTRACTORS, { variables });

  useEffect(() => {
    if (data && data.customers) {
      setContractors(data.customers.edges.map(({ node }: any) => node));
    }
  }, [data]);

  return { contractors, loading, refetch };
}

export default useCustomers;
