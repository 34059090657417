import './assets/scss/main.scss';
import * as Parse from 'parse';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './shared/PrivateRoute';
import Main from './components/Main';
import Login from './components/Login';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import Amplify from 'aws-amplify';

Amplify.configure({
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {}
});

const link = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_URL
});
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      'x-parse-application-id': process.env.REACT_APP_APPLICATION_ID,
      'x-parse-rest-api-key': process.env.REACT_APP_CLIENT_KEY,
      ...(token ? { 'x-parse-session-token': token } : {})
    }
  };
});

const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache({
    typePolicies: {
      AuthorizedList: {
        fields: {
          people: {
            merge: false
          }
        }
      }
    }
  })
});

function App() {
  // @ts-ignore
  Parse.initialize(process.env.REACT_APP_APPLICATION_ID, process.env.REACT_APP_CLIENT_KEY);
  // @ts-ignore
  Parse.serverURL = process.env.REACT_APP_SERVER_URL;

  return (
    <ApolloProvider client={client}>
      <Switch>
        <Route path="/login" exact component={Login} />
        <PrivateRoute component={Main} path="/" />
      </Switch>
    </ApolloProvider>
  );
}

export default App;
