import { Component } from 'react';
import styles from './CustomToggle.module.css';

type Props = {
  /** the bootstrap role */
  bsRole: 'toggle';
  /** the logged in user's first name */
  firstName: string;
  /** the logged in user's last name */
  lastName: string;
  /** the function to call when the user wants to toggle the menu */
  onClick?: () => null;
  /** true if the menu should be open */
  open?: boolean;
};
type State = {};

// NOTE: This must be a class component, functional components do not work as custom toggles.
class CustomToggle extends Component<Props, State> {
  render() {
    const classes = this.props.open ? [styles.userMenu, styles.open] : [styles.userMenu];

    return (
      <span className={classes.join(' ')} onClick={this.props.onClick}>
        <span>
          {this.props.firstName} {this.props.lastName}
        </span>
      </span>
    );
  }
}

export default CustomToggle;
