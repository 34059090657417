import { useCallback } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

type UseInvitationType = {
  /** the number of invitations matching the given email address */
  count: number;
  /** true if the server is processing the request */
  loading: boolean;
  /** the function to call to get the count of invitations matching an email address */
  query: (recipient: string) => Promise<any>;
};

export const GET_INVITATION_BY_RECIPIENT = gql`
  query GetCountInvitations($email: String!) {
    invitations(where: { recipient: { equalTo: $email } }) {
      count
    }
  }
`;

function useCountInvitations(): UseInvitationType {
  const [count, setCount] = useState<number>(-1);
  const { data, loading, refetch } = useQuery(GET_INVITATION_BY_RECIPIENT, {
    fetchPolicy: 'no-cache',
    skip: true
  });

  useEffect(() => {
    if (data?.invitations) {
      setCount(data.invitations.count);
    }
  }, [data]);

  const countInvitations = useCallback(
    (recipient: string) => {
      return refetch({ email: recipient });
    },
    [refetch]
  );

  return { count, loading, query: countInvitations };
}

export default useCountInvitations;
