import { useCallback } from 'react';
import { ApolloError, gql, useMutation } from '@apollo/client';
import { Auth } from 'aws-amplify';
import { useProfile } from './useProfile';

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($id: ID!, $password: String!) {
    updateUser(input: { id: $id, fields: { password: $password } }) {
      clientMutationId
      user {
        email
        id
        username
      }
    }
  }
`;

export type ChangePasswordType = {
  data?: any;
  error?: ApolloError;
  loading?: boolean;
  mutate: (oldPassword: string, newPassword: string, userId?: string) => Promise<any>;
};

export function useChangePassword(): ChangePasswordType {
  const [mutate] = useMutation(CHANGE_PASSWORD);
  const { id, user } = useProfile();
  const changePassword = useCallback(
    (oldPassword, newPassword) => {
      if (user) {
        return Auth.changePassword(user, oldPassword, newPassword);
      }
      const variables = { id, password: newPassword };
      return mutate({ variables }).then(({ data }) => data && data.updateUser.user);
    },
    [id, mutate, user]
  );

  return { mutate: changePassword };
}
