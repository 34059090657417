import Button from 'react-bootstrap/lib/Button';
import { Sizes } from 'react-bootstrap';
import { PulseLoader } from 'react-spinners';

type Props = {
  /** true if the button should be displayed as a block */
  block?: boolean;
  /** the react-bootstrap button sizes */
  bsSize?: Sizes;
  /** the react-bootstrap style */
  bsStyle?: string | null | undefined;
  children: any;
  /** true if the button should be disabled */
  disabled: boolean;
};

function LoadingButton({ block = false, bsSize, bsStyle = 'primary', children, disabled }: Props) {
  return (
    <Button block={block} bsSize={bsSize} bsStyle={bsStyle} disabled={disabled} type="submit">
      {children}
      {disabled && (
        <span className="pull-right" data-testid="loading-animation">
          <PulseLoader color="white" size="10px" />
        </span>
      )}
    </Button>
  );
}

export default LoadingButton;
