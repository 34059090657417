import { useReducer } from 'react';
import logo from '../../assets/img/logo-dark.png';
import * as qs from 'qs';
import { useHistory } from 'react-router-dom';
import LoginForm from './LoginForm';
import NewPasswordForm from './NewPasswordForm';
import reducer, { initialize } from './reducer';
import { MFAType, VisibleForm } from './types';
import TokenAuthForm from './TokenAuthForm';
import * as Parse from 'parse';
import { useLegacyLogin, useLogin } from '../../shared/hooks/profiles';
import { LoginPayload } from '../../shared/hooks/profiles/useLogin';
import ForgotPasswordForm from './ForgotPasswordForm';
import VerifyAccountForm from './VerifyAccountForm';

function Login() {
  const [state, dispatch] = useReducer(reducer, {}, initialize);
  const { mutate: legacyLogin } = useLegacyLogin();
  const { mutate: login } = useLogin();
  const history = useHistory();

  const handleMFAChallenge = (user: any, mfaType: MFAType, email: string) => {
    dispatch({ type: 'showMFAForm', email, mfaType, user });
  };

  const handlePasswordChallenge = (user: any, email: string) => {
    dispatch({ type: 'showPasswordForm', email, user });
  };

  const handleForgotPassword = (email: string) => {
    dispatch({ type: 'showForgotPasswordForm', email });
  };

  const handleNewAccount = (email: string) => {
    dispatch({ type: 'showNewAccount', email });
  };

  const handleRedirect = () => {
    const next = (qs.parse(history.location.search.substring(1)).next || '/dashboard') as string;
    history.push(next);
  };

  const handleSessionToken = async (payload: LoginPayload) => {
    localStorage.setItem('token', payload.sessionToken);
    localStorage.setItem('user', JSON.stringify(payload.user));

    // Release valve for testing, otherwise we'll get an error that "it's not safe to become
    // a user in a server environment".
    // TODO: This is only temporary until we convert everything to use GraphQL
    if (process.env.NODE_ENV === 'test') {
      return true;
    }
    /* istanbul ignore next */
    return Parse.User.become(payload.sessionToken);
  };

  const handleLoginSuccess = (
    userId: string,
    accessToken: string,
    email: string,
    firstName: string,
    lastName: string
  ) => {
    return login(userId, accessToken, email, firstName, lastName)
      .then(handleSessionToken)
      .then(handleRedirect);
  };

  const handleLegacyLogin = (username: string, password: string) => {
    return legacyLogin(username, password).then(handleSessionToken).then(handleRedirect);
  };

  return (
    <div id="wrapper" style={{ marginTop: '25px' }}>
      <div className="vertical-align-wrap">
        <div className="vertical-align-middle">
          <div className="auth-box ">
            <div className="left">
              <div className="content">
                <div className="header">
                  <div className="logo text-center">
                    <img src={logo} alt="MozaicID logo" />
                  </div>
                  <p className="lead">Login to your account</p>
                </div>
                {state.visibleForm === VisibleForm.Login && (
                  <LoginForm
                    onForgotPassword={handleForgotPassword}
                    onLegacyLogin={handleLegacyLogin}
                    onMFA={handleMFAChallenge}
                    onNewAccount={handleNewAccount}
                    onNewPassword={handlePasswordChallenge}
                    onSuccess={handleLoginSuccess}
                  />
                )}
                {state.visibleForm === VisibleForm.NewPassword && (
                  <NewPasswordForm
                    email={state.email}
                    onSuccess={handleLoginSuccess}
                    user={state.user}
                  />
                )}
                {state.visibleForm === VisibleForm.MultiFactorAuth && (
                  <TokenAuthForm
                    email={state.email}
                    mfaType={state.mfaType}
                    onSuccess={handleLoginSuccess}
                    user={state.user}
                  />
                )}
                {state.visibleForm === VisibleForm.ForgotPassword && (
                  <ForgotPasswordForm
                    onSuccess={() => dispatch({ type: 'showLoginForm' })}
                    username={state.email}
                  />
                )}
                {state.visibleForm === VisibleForm.NewAccount && (
                  <VerifyAccountForm
                    email={state.email}
                    onSuccess={() => dispatch({ type: 'showLoginForm' })}
                  />
                )}
              </div>
            </div>
            <div className="right">
              <div className="overlay" />
              <div className="content text">
                <h1 className="heading">MozaicID Portal</h1>
              </div>
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
