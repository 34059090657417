import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAnchor,
  faArrowsH,
  faEnvelope,
  faHome,
  faIdCard,
  faListOl,
  faMapMarkerAlt,
  faMobileAndroidAlt,
  faQuestionCircle,
  faRssSquare,
  faUser
} from '@fortawesome/pro-light-svg-icons';
import SidebarItem from './SidebarItem';
import { useProfile } from '../../../shared/hooks/profiles';
import CollapsableSidebarItem from './CollapsableSidebarItem';

function Sidebar() {
  const toggleMinified = () => {
    document.body.classList.toggle('sidebar-minified');
  };
  const { customerAdmin, fullAdmin } = useProfile();
  const eitherAdmin = fullAdmin || customerAdmin;
  let showLists = customerAdmin;

  // Override the showLists value if the environment tells us to.
  if (process.env.REACT_APP_SHOW_LISTS && process.env.REACT_APP_SHOW_LISTS === 'false') {
    showLists = false;
  }

  return (
    <div id="sidebar-nav" className="sidebar" data-testid="mozaicid-sidebar">
      <nav>
        <ul className="nav" id="sidebar-nav-menu">
          <SidebarItem icon={faHome} title="Dashboard" to="/dashboard" />
          {fullAdmin && <SidebarItem icon={faAnchor} title="Customers" to="/customers" />}
          {customerAdmin && <SidebarItem icon={faAnchor} title="Contractors" to="/customers" />}
          {!fullAdmin && <SidebarItem icon={faMapMarkerAlt} title="Locations" to="/locations" />}
          {customerAdmin && (
            <SidebarItem icon={faRssSquare} title="Notifications" to="/notifications" />
          )}
          {eitherAdmin && <SidebarItem icon={faUser} title="Users" to="/users" />}
          <SidebarItem icon={faMobileAndroidAlt} title="Devices" to="/devices" />
          <SidebarItem icon={faIdCard} title="Uploads" to="/uploads" />
          {showLists && (
            <CollapsableSidebarItem icon={faListOl} root="/lists/" title="Lists">
              <SidebarItem submenu title="Pre-Authorized" to="/lists/preauth" />
              <SidebarItem submenu title="Visitors" to="/lists/visitors" />
              <SidebarItem submenu title="Watch List" to="/lists/watch" />
            </CollapsableSidebarItem>
          )}
          <SidebarItem icon={faQuestionCircle} title="Support" to="/support" />
          {!fullAdmin && <SidebarItem icon={faEnvelope} title="Contact Us" to="/contact" />}
        </ul>
        <button
          type="button"
          className="btn-toggle-minified"
          title="Toggle Minified Menu"
          onClick={toggleMinified}
        >
          <FontAwesomeIcon icon={faArrowsH} />
        </button>
      </nav>
    </div>
  );
}

export default Sidebar;
