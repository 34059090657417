type Props = {
  /** child elements to display within this wrapper */
  children: any;
  /** true if the loading animation should be shown in lieu of the children */
  isBusy: boolean;
  /** the loader component */
  Loader: any;
};

function AppIsBusyWrapper({ children, isBusy, Loader }: Props) {
  return isBusy ? (
    <div style={{ paddingTop: '50px' }}>
      <Loader />
    </div>
  ) : (
    children
  );
}

export default AppIsBusyWrapper;
