import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { InvitationType } from '../../types';

type UseInvitationsType = {
  /** the list of invitations that have not been converted to users yet */
  invitations: InvitationType[];
  /** true if the server is processing the request */
  loading: boolean;
  /** function to call to force a refresh of the data */
  refetch: () => Promise<any>;
};

export const GET_ALL_INVITATIONS = gql`
  query GetInvitations($where: InvitationWhereInput) {
    invitations(order: [recipient_ASC], where: $where) {
      count
      edges {
        node {
          customer {
            name
            objectId
          }
          objectId
          recipient
          sender
        }
      }
    }
  }
`;

function useInvitations(customerId?: string): UseInvitationsType {
  const [invitations, setInvitations] = useState<InvitationType[]>([]);
  const { data, loading, refetch } = useQuery(GET_ALL_INVITATIONS, {
    fetchPolicy: 'no-cache',
    variables: {
      ...(customerId
        ? { where: { customer: { have: { objectId: { equalTo: customerId } } } } }
        : {})
    }
  });

  useEffect(() => {
    if (data?.invitations) {
      setInvitations(data.invitations.edges.map(({ node }: any) => node));
    }
  }, [data]);

  return { invitations, loading, refetch };
}

export default useInvitations;
