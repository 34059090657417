import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown, faAngleLeft } from '@fortawesome/pro-light-svg-icons';
import { useLocation } from 'react-router-dom';

type Props = {
  children: any;
  /** the FontAwesome icon to be displayed */
  icon: IconProp;
  /** the root URL we should look for to determine if this should be active/open */
  root: string;
  /** the text to display next to the icon */
  title: string;
};

function CollapsableSidebarItem({ children, icon, root, title }: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [activeClass, setActiveClass] = useState<string>('');
  const angleIcon = open ? faAngleDown : faAngleLeft;
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith(root)) {
      setOpen(true);
      setActiveClass('active');
    } else {
      setActiveClass('');
    }
  }, [location.pathname, root]);

  return (
    <li className="panel">
      <a
        data-toggle="collapse"
        onClick={() => setOpen(!open)}
        style={{ cursor: 'pointer' }}
        className={activeClass}
      >
        <FontAwesomeIcon icon={icon} style={{ marginRight: '10px', fontSize: '17px' }} />
        <span className="title">{title}</span>
        <FontAwesomeIcon className="icon-submenu" icon={angleIcon} />
      </a>
      {open && (
        <div className="collapse in">
          <ul className="submenu">{children}</ul>
        </div>
      )}
    </li>
  );
}

export default CollapsableSidebarItem;
