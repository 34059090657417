import { CustomerQuery, CustomerType } from '../../types';
import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

type UseCustomerType = {
  /** the customer object for the given customer ID */
  customer?: CustomerType;
  /** true if the server is processing the request */
  loading: boolean;
};

export const GET_CUSTOMER = gql`
  query getSingleCustomer($id: ID!) {
    customer(id: $id) {
      active
      createdAt
      groups {
        ... on Element {
          value
        }
      }
      id
      licenses
      name
      objectId
      parent {
        id
        objectId
      }
      portal_renewal
    }
  }
`;

function useCustomer(customerId?: string): UseCustomerType {
  const [customer, setCustomer] = useState<CustomerType | undefined>(undefined);
  const { data, loading } = useQuery<CustomerQuery>(GET_CUSTOMER, {
    skip: !customerId,
    variables: { id: customerId }
  });

  useEffect(() => {
    if (data?.customer) {
      setCustomer({
        ...data.customer,
        ...(data.customer.groups ? { groups: data.customer.groups.map((g) => g.value) } : {})
      });
    }
  }, [data]);

  return { customer, loading };
}

export default useCustomer;
