import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

export type LoginPayload = {
  /** the Parse session token */
  sessionToken: string;
  user: {
    /** the user's email address */
    email: string;
    /** the unique ID of the user */
    id: string;
    /** true if the user logged in via the legacy process */
    isLegacy?: boolean;
    /** the username of the account */
    username: string;
  };
};

export type LoginType = {
  /** true if the login process is active */
  loading: boolean;
  /** the function to call to initiate the login process */
  mutate: (
    userId: string,
    token: string,
    email: string,
    firstName: string,
    lastName: string
  ) => Promise<LoginPayload>;
};

export const PERFORM_LOGIN = gql`
  mutation PerformLogIn(
    $email: String!
    $authData: Object!
    $firstName: String!
    $lastName: String!
  ) {
    logInWith(
      input: {
        authData: $authData
        fields: {
          email: $email
          email_address: $email
          first_name: $firstName
          last_name: $lastName
        }
      }
    ) {
      viewer {
        sessionToken
        user {
          email
          id
          username
        }
      }
    }
  }
`;

/**
 * This function will attempt to log the user in. On a successful login, we'll return the user
 * object and the new session token.
 */
export function useLogin(): LoginType {
  const [mutate, { loading }] = useMutation(PERFORM_LOGIN);

  const handleLogin = useCallback(
    (userId, token, email, firstName, lastName) => {
      return mutate({
        variables: { authData: { cognito: { id: userId, token } }, email, firstName, lastName }
      }).then(({ data }) => (data && data.logInWith ? data.logInWith.viewer : null));
    },
    [mutate]
  );

  return { loading, mutate: handleLogin };
}
