import { useState } from 'react';
import * as Yup from 'yup';
import { Auth } from 'aws-amplify';
import { Form, Formik } from 'formik';
import Alert from 'react-bootstrap/lib/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import TextInput from '../../../shared/Form/TextInput';
import LoadingButton from '../../../shared/Buttons/Loading';
import Button from 'react-bootstrap/lib/Button';
import { PulseLoader } from 'react-spinners';

type Props = {
  /** the user's email address */
  email: string;
  /** the function to call when the validation is successful */
  onSuccess: () => void;
};

function VerifyAccountForm({ email, onSuccess }: Props) {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [resend, setResend] = useState<boolean>(false);

  const initialValues = { code: '' };
  const schema = Yup.object().shape({
    code: Yup.string().required('Required')
  });

  const handleResendCode = () => {
    setResend(true);
    Auth.resendSignUp(email).then(() => {
      setResend(false);
    });
  };

  const handleSubmit = ({ code }: any) => {
    setLoading(true);
    setErrorMessage('');
    Auth.confirmSignUp(email, code)
      .then(() => {
        setLoading(false);
        setComplete(true);
      })
      .catch((err) => {
        setErrorMessage(err.message);
        setLoading(false);
      });
  };

  return (
    <Formik
      className="form-auth-small"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      <Form>
        {!errorMessage && (
          <Alert bsStyle="info">
            A six digit verification code has been sent to your email. Please enter the code below.
          </Alert>
        )}
        {errorMessage && (
          <Alert bsStyle="danger" data-testid="login-message">
            <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '15px' }} />
            {errorMessage}
          </Alert>
        )}
        <TextInput autoFocus name="code" type="text" autoComplete="off" placeholder="Enter code" />
        {!complete && (
          <>
            <div style={{ marginTop: '20px' }}>
              <Button bsStyle="link" onClick={() => handleResendCode()}>
                Resend verification code
              </Button>
              {resend && (
                <span>
                  <PulseLoader size="8px" color="#44444444" />
                </span>
              )}
            </div>
            <div style={{ marginTop: '20px' }}>
              <LoadingButton block bsSize="large" disabled={loading}>
                Complete Registration
              </LoadingButton>
            </div>
          </>
        )}
        {complete && (
          <div style={{ marginTop: '20px' }}>
            <Button bsStyle="link" onClick={() => onSuccess()}>
              Registration complete, click here to log in
            </Button>
          </div>
        )}
      </Form>
    </Formik>
  );
}

export default VerifyAccountForm;
