import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { InvitationModalOutput } from '../../types';

export type CreateInvitationType = {
  /** true if the server is processing the request */
  loading: boolean;
  /** the function to call to create a new invitation object */
  mutate: (value: InvitationModalOutput) => Promise<any>;
};

export const CREATE_INVITATION = gql`
  mutation CreateInvitation($fields: CreateInvitationFieldsInput!) {
    createInvitation(input: { fields: $fields }) {
      invitation {
        customer {
          objectId
        }
        recipient
        sender
      }
    }
  }
`;

function useCreateInvitation(): CreateInvitationType {
  const [mutate, { loading }] = useMutation(CREATE_INVITATION);

  const createInvitation = useCallback(
    (values: InvitationModalOutput) => {
      return mutate({
        variables: {
          fields: {
            ...(values.customer ? { customer: { link: values.customer } } : {}),
            recipient: values.recipient,
            sender: values.sender
          }
        }
      });
    },
    [mutate]
  );

  return { loading, mutate: createInvitation };
}

export default useCreateInvitation;
