import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import logo from '../../../assets/img/logo-white2.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronCircleLeft,
  faChevronCircleRight,
  faPowerOff,
  faUser
} from '@fortawesome/pro-light-svg-icons';
import Navbar from 'react-bootstrap/lib/Navbar';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import CustomToggle from './CustomToggle';
import { useLogout, useProfile } from '../../../shared/hooks/profiles';

function Header() {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const profile = useProfile();
  const history = useHistory();
  const { mutate: logout } = useLogout();

  // TODO: This needs to use the "useLogout" hook when we fully move to GraphQL
  const handleLogout = () => {
    logout().then(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      history.push('/login?next=/dashboard');
    });
  };

  const toggleFullLayout = () => {
    document.body.classList.toggle('layout-fullwidth');
    if (window.innerWidth < 1025) {
      document.body.classList.toggle('offcanvas-active');
    }
    setIsFullScreen(!isFullScreen);
  };

  return (
    <Navbar fixedTop fluid>
      <div className="brand">
        <Link to="/">
          <img src={logo} alt="MozaicID Solutions" className="img-responsive logo" />
        </Link>
      </div>
      <div className="container-fluid" style={{ paddingLeft: 0 }}>
        <div id="tour-fullwidth" className="navbar-btn">
          <button type="button" className="btn-toggle-fullwidth" onClick={toggleFullLayout}>
            {!isFullScreen && <FontAwesomeIcon icon={faChevronCircleLeft} />}
            {isFullScreen && <FontAwesomeIcon icon={faChevronCircleRight} />}
          </button>
        </div>
        <div id="navbar-menu">
          <ul className="nav navbar-nav navbar-right">
            <Dropdown id="user-menu-dropdown" data-testid="user-menu">
              <CustomToggle
                bsRole="toggle"
                firstName={profile.firstName}
                lastName={profile.lastName}
              />
              <Dropdown.Menu>
                <MenuItem onClick={() => history.push('/profile')}>
                  <FontAwesomeIcon icon={faUser} style={{ marginRight: '6px' }} />
                  <span>My Profile</span>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <FontAwesomeIcon icon={faPowerOff} style={{ marginRight: '6px' }} />
                  <span>Logout</span>
                </MenuItem>
              </Dropdown.Menu>
            </Dropdown>
          </ul>
        </div>
      </div>
    </Navbar>
  );
}

export default Header;
