import { useContext } from 'react';
import ProfileContext from '../../contexts/ProfileContext';
import { MFAType } from '../../../components/Login/types';

export type UserProfileType = {
  /** true if the user is in the "CustomerAdmins" group */
  customerAdmin: boolean;
  /** the customer ID the user belongs to (if not a full admin) */
  customerId?: string;
  /** the user's email address */
  email: string;
  /** the user's first (or given) name */
  firstName: string;
  /** true if the user is in the "Admins" group */
  fullAdmin: boolean;
  /** the Parse User ID */
  id?: string;
  /** the user's last (or family) name */
  lastName: string;
  /** the user's preferred multi-factor authentication method */
  mfaType: MFAType;
  /** the list of non-admin roles the user belongs to */
  roles: string[];
  /** the function to call to update the internal profile state */
  updateProfile: (firstName: string, lastName: string) => void;
  /** the Cognito user */
  user: any;
};

export function useProfile(): UserProfileType {
  const context = useContext(ProfileContext);

  return context as UserProfileType;
}
