import { useState } from 'react';
import { Form, Formik } from 'formik';
import Alert from 'react-bootstrap/lib/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import TextInput from '../../../shared/Form/TextInput';
import * as Yup from 'yup';
import { Auth } from 'aws-amplify';
import LoadingButton from '../../../shared/Buttons/Loading';

type Props = {
  /** the function to call when the password reset was successful */
  onSuccess: () => void;
  /** the username of the user who requested their password be reset */
  username: string;
};

type SubmitProps = {
  /** the password reset code the user should have received in an email */
  code: string;
  /** the user's new password */
  password1: string;
  /** a duplicate of the user's new password */
  password2: string;
};

function ForgotPasswordForm({ onSuccess, username }: Props) {
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);

  const initialValues = {
    code: '',
    password1: '',
    password2: ''
  };
  const schema = Yup.object().shape({
    code: Yup.string().required('Enter the code you received in the email'),
    password1: Yup.string().required('A password is required'),
    password2: Yup.string().oneOf([Yup.ref('password1'), null], 'Passwords do not match')
  });

  const handleSubmit = ({ code, password1 }: SubmitProps) => {
    setLoading(true);
    Auth.forgotPasswordSubmit(username, code, password1)
      .then(() => setSuccessful(true))
      .catch((err) => {
        setErrorMessage(err.message);
        setLoading(false);
      });
  };

  return (
    <Formik
      className="form-auth-small"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      <Form>
        {!errorMessage && (
          <Alert bsStyle="info">
            Please enter the code you received in an email and a new password
          </Alert>
        )}
        {errorMessage && (
          <Alert bsStyle="danger" data-testid="login-message">
            <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '15px' }} />
            {errorMessage}
          </Alert>
        )}
        <TextInput name="code" autoComplete="off" placeholder="Enter the password reset code" />
        <TextInput
          name="password1"
          type="password"
          autoComplete="off"
          placeholder="Enter a new password"
        />
        <TextInput
          name="password2"
          type="password"
          autoComplete="off"
          placeholder="Re-type your new password"
        />
        {!successful && (
          <div style={{ marginTop: '20px' }}>
            <LoadingButton block bsSize="large" disabled={loading}>
              RESET PASSWORD
            </LoadingButton>
          </div>
        )}
        {successful && (
          <Alert bsStyle="success" onClick={() => onSuccess()} style={{ cursor: 'pointer' }}>
            Password reset was successful! Click here to log in again.
          </Alert>
        )}
      </Form>
    </Formik>
  );
}

export default ForgotPasswordForm;
