import { useEffect, useState } from 'react';
import { gql, useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { CustomerType } from '../../types';

type UseCustomersType = {
  /** the list of customers returned from the query */
  customers: CustomerType[];
  /** true if the server is processing the request */
  loading: boolean;
  /** the function to call to refetch the query */
  refetch: () => Promise<any>;
};

// This will fetch all of the contractors (i.e. those that have a parent matching the given
// customer ID).
export const GET_ALL_CONTRACTORS = gql`
  query getContractors($customerId: ID!, $sort: [CustomerOrder!]) {
    customers(order: $sort, where: { parent: { have: { id: { equalTo: $customerId } } } }) {
      edges {
        node {
          active
          createdAt
          groups {
            ... on Element {
              value
            }
          }
          id
          licenses
          name
          objectId
          parent {
            id
            objectId
          }
          portal_renewal
        }
      }
    }
  }
`;

// This will fetch all of the "top-level" customers (i.e. those that do not have a parent).
export const GET_ALL_CUSTOMERS = gql`
  query getCustomers($sort: [CustomerOrder!]) {
    customers(order: $sort, where: { parent: { exists: false } }) {
      edges {
        node {
          active
          createdAt
          groups {
            ... on Element {
              value
            }
          }
          id
          licenses
          name
          objectId
          parent {
            id
            objectId
          }
          portal_renewal
        }
      }
    }
  }
`;

/**
 * This hook will fetch all of the top-level customers or all of the contractors under a specific
 * customer.
 *
 * @param customerId - the customer ID to filter the results on
 * @param fetchPolicy - the Apollo fetch policy to apply to the query
 */
function useCustomers(customerId?: string, fetchPolicy?: WatchQueryFetchPolicy): UseCustomersType {
  const [customers, setCustomers] = useState([]);
  const query = customerId ? GET_ALL_CONTRACTORS : GET_ALL_CUSTOMERS;
  const variables = customerId ? { customerId, sort: ['name_ASC'] } : { sort: ['name_ASC'] };
  const { data, loading, refetch } = useQuery(query, { fetchPolicy, variables });

  useEffect(() => {
    if (data && data.customers) {
      setCustomers(data.customers.edges.map(({ node }: any) => node));
    }
  }, [data]);

  return { customers, loading, refetch };
}

export default useCustomers;
