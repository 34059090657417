import { useEffect } from 'react';
import { Form, useFormikContext } from 'formik';
import Alert from 'react-bootstrap/lib/Alert';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import Row from 'react-bootstrap/lib/Row';
import TextInput from '../../../shared/Form/TextInput';
import { RegistrationValues } from '../types';

type Props = {
  /** true if the signup process is in progress */
  busy?: boolean;
  /** the function to call when the error alert is dismissed */
  onDismissError: (value: string | null) => void;
  /** an error message from the signup process */
  serverError: string | null;
};

function RegistrationForm({ busy = false, onDismissError, serverError }: Props) {
  // State
  const { dirty, errors, initialValues, isValid, validateField } =
    useFormikContext<RegistrationValues>();

  // Validate the initial username if there is one
  useEffect(() => {
    if (initialValues.username.length > 1) {
      validateField('username');
    }
  }, [initialValues.username, validateField]);

  return (
    <Form>
      <Modal.Body>
        {serverError && (
          <Alert bsStyle="danger" onDismiss={() => onDismissError(null)}>
            <h4>Registration Error</h4>
            <p>{serverError}</p>
          </Alert>
        )}
        <Row>
          <TextInput
            disabled={!!initialValues.username && !errors.username}
            fgClass="col-xs-12"
            label="Email Address"
            name="username"
            type="email"
            placeholder="Email address"
          />
        </Row>
        <Row>
          <TextInput
            fgClass="col-md-6"
            label="First Name"
            name="firstName"
            type="text"
            placeholder="First name"
          />
          <TextInput
            fgClass="col-md-6"
            label="Last Name"
            name="lastName"
            type="text"
            placeholder="Last name"
          />
        </Row>
        <Row>
          <TextInput
            fgClass="col-xs-12"
            label="Password"
            autoComplete="off"
            name="password"
            type="password"
            placeholder="Account password"
          />
        </Row>
        <Row>
          <TextInput
            fgClass="col-xs-12"
            label="Confirm Password"
            autoComplete="off"
            name="passwordConfirm"
            type="password"
            placeholder="Verify password"
          />
        </Row>
      </Modal.Body>
      <Modal.Footer style={{ textAlign: 'center' }}>
        <Button bsStyle="primary" disabled={busy || !dirty || !isValid} type="submit">
          Create Account
        </Button>
      </Modal.Footer>
    </Form>
  );
}

export default RegistrationForm;
