export type LoginSuccessFunc = (
  userId: string,
  jwt: string,
  email: string,
  firstName: string,
  lastName: string
) => Promise<any>;

export type MFAType = 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA' | null;

export interface RegistrationValues {
  firstName: string;
  lastName: string;
  password: string;
  passwordConfirm: string;
  username: string;
}

export enum VisibleForm {
  ForgotPassword,
  Login,
  MultiFactorAuth,
  NewAccount,
  NewPassword
}
