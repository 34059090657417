import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import styles from '../Login.module.css';
import { useHistory, useLocation } from 'react-router-dom';
import RegistrationModal from '../RegistrationModal';

type Props = {
  /** the function to call when an account is created successfully */
  onSuccess: (email: string) => void;
};

// Helpers
function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

function CreateAccount({ onSuccess }: Props) {
  const history = useHistory();
  const queryParams = useQueryParams();
  const signUp = queryParams.get('signUp');

  return (
    <>
      <span>
        <FontAwesomeIcon icon={faUser} />
        <span
          className={styles.formLink}
          onClick={() => history.replace({ pathname: '/login', search: '?signUp=true' })}
        >
          Create a new account
        </span>
      </span>
      <RegistrationModal
        asUser={signUp}
        onClose={() => history.replace({ pathname: '/login' })}
        onSuccess={(email) => {
          history.replace({ pathname: '/login', search: '' });
          onSuccess(email);
        }}
        show={!!signUp}
      />
    </>
  );
}

export default CreateAccount;
