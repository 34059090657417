import { useCallback } from 'react';
import { gql } from '@apollo/client';
import * as Parse from 'parse';
import { Auth } from 'aws-amplify';

export type LogoutResponse = {
  loading?: boolean;
  mutate: () => Promise<any>;
};

// noinspection JSUnusedGlobalSymbols
export const PERFORM_LOGOUT = gql`
  mutation PerformLogOut {
    logOut(input: { clientMutationId: "logOut" }) {
      clientMutationId
    }
  }
`;

/**
 * This function will attempt to log out the user.
 */
export function useLogout(): LogoutResponse {
  // Start by logging the user out of the Parse backend. If the user is also found in Cognito,
  // then we'll log them out of there too.
  const handleLogout = useCallback(() => {
    return Parse.User.logOut()
      .then(() => Auth.signOut())
      .catch((err) => console.log(err));
  }, []);

  return { mutate: handleLogout };
}
