import { useCallback } from 'react';
import { ApolloError, gql, useMutation } from '@apollo/client';
import { Auth } from 'aws-amplify';
import { useProfile } from './useProfile';

export const EDIT_PROFILE = gql`
  mutation EditProfile($firstName: String, $id: ID!, $lastName: String) {
    updateUser(input: { id: $id, fields: { first_name: $firstName, last_name: $lastName } }) {
      clientMutationId
      user {
        first_name
        id
        last_name
      }
    }
  }
`;

export type EditProfileProps = {
  /** the user's first name */
  firstName: string;
  /** the user's unique ID */
  id?: string;
  /** the user's last name */
  lastName: string;
};

export type EditProfileType = {
  /** the updated user profile after the edit was successful */
  data?: any;
  /** this function will turn MFA off for the current user */
  disableMFA: () => Promise<any>;
  /** this function will turn MFA on for the current user */
  enableMFA: () => Promise<any>;
  /** a string representing any possible errors */
  error?: ApolloError;
  /** true if the server is processing the edit */
  loading?: boolean;
  /** the function to call to initiate updating the user's profile */
  mutate: (values: EditProfileProps) => Promise<any>;
};

/**
 * This function will allow a user to edit their profile.
 */
export function useEditProfile(): EditProfileType {
  const [mutate, { data, error, loading }] = useMutation(EDIT_PROFILE);
  const { updateProfile, user } = useProfile();
  const editProfile = useCallback(
    (values: EditProfileProps) => {
      if (!user) {
        return mutate({ variables: values }).then(({ data }) => data && data.updateUser.profile);
      }
      return Auth.updateUserAttributes(user, {
        family_name: values.lastName,
        given_name: values.firstName
      }).then(() => {
        updateProfile(values.firstName, values.lastName);
      });
    },
    [mutate, updateProfile, user]
  );

  // This function will turn MFA on for a user.
  const enableMFA = useCallback(() => Auth.setPreferredMFA(user, 'TOTP'), [user]);

  // This function will turn MFA off for a user.
  const disableMFA = useCallback(() => Auth.setPreferredMFA(user, 'NOMFA'), [user]);

  return { data, disableMFA, enableMFA, error, loading, mutate: editProfile };
}
