import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

export const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($customerId: ID!) {
    deleteCustomer(input: { id: $customerId }) {
      clientMutationId
      customer {
        id
        objectId
      }
    }
  }
`;

function useDeleteCustomer() {
  const [mutate, { data, error, loading }] = useMutation(DELETE_CUSTOMER);
  const deleteCustomer = useCallback(
    (itemId) => {
      return mutate({ variables: { customerId: itemId } });
    },
    [mutate]
  );

  return { data, error, loading, mutate: deleteCustomer };
}

export default useDeleteCustomer;
