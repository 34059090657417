import { useCallback } from 'react';
import { Auth } from 'aws-amplify';

/**
 * This function will attempt to sign up the given user.
 */
export function useSignUp() {
  const handleSignUp = useCallback((username, password, given_name, family_name) => {
    return Auth.signUp({ username, password, attributes: { given_name, family_name } });
  }, []);

  return { mutate: handleSignUp };
}
