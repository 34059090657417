import { useEffect, useReducer, useRef } from 'react';
import { gql, useQuery } from '@apollo/client';

export type UserRolesType = {
  /** string representing the customer the user belongs to */
  customerId?: string;
  /** true if the user is in the "CustomerAdmins" group */
  customerAdmin: boolean;
  /** true if the user is in the "Admins" group */
  fullAdmin: boolean;
  /** list of all roles the user belongs to */
  roles: string[];
};

type UserRolesLoading = {
  /** the error message that may have occurred when fetching roles */
  error?: string;
  /** true if the roles are being fetched from the server */
  loading: boolean;
};

type Action = { type: 'setRoles'; roles: string[] };

export const GET_USER_ROLES = gql`
  query getMyRoles($userId: ID!) {
    roles(where: { users: { have: { id: { equalTo: $userId } } } }) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

function reducer(state: UserRolesType, action: Action): UserRolesType {
  switch (action.type) {
    case 'setRoles':
      const customers = action.roles.filter(
        (r: string) => r !== 'Admins' && r !== 'CustomerAdmins'
      );
      return {
        ...state,
        customerId: customers.length > 0 ? customers[0] : undefined,
        customerAdmin: action.roles.includes('CustomerAdmins'),
        fullAdmin: action.roles.includes('Admins'),
        roles: action.roles
      };
    default:
      return state;
  }
}

function initialize(): UserRolesType {
  return {
    customerAdmin: false,
    fullAdmin: false,
    roles: []
  };
}

export function useMyRoles(): UserRolesType & UserRolesLoading {
  const mounted = useRef(true);
  const [state, dispatch] = useReducer(reducer, {}, initialize);
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { data, error, loading } = useQuery(GET_USER_ROLES, {
    skip: !user || !user.id,
    variables: { userId: user && user.id }
  });

  useEffect(() => {
    mounted.current = true;
    if (data && !error) {
      const roleEdges = data.roles.edges.length > 0 ? data.roles.edges : [];
      const roles = roleEdges.map((r: any) => r.node.name);
      if (mounted) {
        dispatch({ type: 'setRoles', roles });
      }
    }

    return () => {
      mounted.current = false;
    };
  }, [data, error]);

  return {
    ...state,
    ...(error ? { error: error.message } : {}),
    loading
  };
}
