import { Redirect, Route } from 'react-router-dom';

type Props = {
  component: any;
  [x: string]: any;
};

function PrivateRoute({ component: Component, ...rest }: Props) {
  const isLoggedIn = !!localStorage.getItem('token');
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to={'/login?next=' + (props.location.pathname || '/dashboard')} />
        )
      }
    />
  );
}

export default PrivateRoute;
