import { useState } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import * as Yup from 'yup';
import { Formik } from 'formik';
import RegistrationForm from '../RegistrationForm';
import { useSignUp } from '../../../shared/hooks/profiles';
import { RegistrationValues } from '../types';
import { useCountInvitations } from '../../../shared/hooks/invitations';

type Props = {
  /** the username to pre-fill */
  asUser?: string | null;
  /** the function to call when the modal is to be closed */
  onClose: () => void;
  /** the function to call when the signup process is successful */
  onSuccess: (email: string) => void;
  /** true if the modal should be visible */
  show?: boolean;
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('The first name field cannot be blank'),
  lastName: Yup.string().required('The last name field cannot be blank'),
  password: Yup.string().required('The password field cannot be blank'),
  passwordConfirm: Yup.string().when('password', (password, schema) => {
    return password && password.length > 0
      ? schema
          .required('The password field must be verified')
          .oneOf([password, null], 'The passwords do not match')
      : schema.notRequired();
  }),
  username: Yup.string()
    .email('A valid email address is required')
    .required('A valid email address is required')
});

function RegistrationModal({ asUser, onClose, onSuccess, show = false }: Props) {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { mutate } = useSignUp();
  const { query: countInvitations } = useCountInvitations();
  const initialValues: RegistrationValues = {
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirm: '',
    username: typeof asUser === 'string' && asUser !== 'true' ? asUser : ''
  };

  const handleSubmit = (values: RegistrationValues) => {
    setLoading(true);
    countInvitations(values.username)
      .then((response) => {
        if (response.data?.invitations?.count > 0) {
          return Promise.resolve();
        }
        throw new Error('There is no invitation that matches the given email address');
      })
      .then(() => mutate(values.username, values.password, values.firstName, values.lastName))
      .then(() => {
        onSuccess(values.username);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  };

  return (
    <Modal show={show} onHide={() => onClose()} data-testid="mozaicid-registration">
      <Modal.Header closeButton>
        <h3>
          Create an Account
          <br />
          <small>It's quick and easy.</small>
        </h3>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <RegistrationForm busy={loading} onDismissError={setError} serverError={error} />
      </Formik>
    </Modal>
  );
}

export default RegistrationModal;
