function PrivacyPolicy() {
  return (
    <div>
      <h1 style={{ marginTop: 0, paddingTop: '20px' }}>Privacy Notice</h1>
      <div>Last updated: October 13, 2022</div>
      <p>
        This privacy notice for Mozaic Inc. (“Company”, “we”, “us”, or “our”), describes how and why
        we might collect, store, use, and/or share (“process”) your information when you use our
        services (“Services”), such as when you:
        <ul>
          <li>
            Visit our website at https://portal.mozaicid.com or any website of ours that links to
            this privacy notice
          </li>
          <li>Engage with us in other related ways, including any sales, marketing, or events</li>
        </ul>
      </p>
      <p>
        Questions or concerns? Reading this privacy notice will help you understand your privacy
        rights and choices. If you do not agree with our policies and practices, please do not use
        our Services. If you still have any questions or concerns, please contact us at
        support@mozaicid.com.
      </p>
      <h2>Summary of Key Points</h2>
      <p>
        This summary provides key points from our privacy notice, but you can find out more details
        about any of these topics by clicking the link following each key point or by using our
        table of contents below to find the section you are looking for. You can also click here to
        go directly to our table of contents.
      </p>
      <p>
        What personal information do we process? When you visit, use, or navigate our Services we
        may process personal information depending on how you interact with Mozaic Inc. and the
        Services, the choices you make, and the products and features you use.
      </p>
      <p>
        Do we process any sensitive personal information? We do not process sensitive personal
        information.
      </p>
      <p>
        Do we receive any information from third parties? We do not receive any information from
        third parties.
      </p>
      <p>
        How do we process your information? We process your information to provide, improve, and
        administer our Services, communicate with you, for security and fraud prevention, and to
        comply with the law. We may also process your information for other purposes with your
        consent. We process your information only when we have a valid legal reason to do so.
      </p>
      <p>
        In what situations and with which parties do we share personal information? We may share
        information in specific situations. For example with law enforcement for legal reasons.
      </p>
      <p>
        How do we keep your information safe? We have organizational and technical processes and
        procedures in place to protect your personal information. However, no electronic
        transmission over the internet or information storage technology can be guaranteed to be
        100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other
        unauthorized third parties will not be able to defeat our security and improperly collect,
        access, steal, or modify your information.
      </p>
      <p>
        How do you exercise your rights? The easiest way to exercise your rights is by filling out
        our data subject request form available here, or by contacting us. We will consider and act
        upon any request in accordance with applicable data protection laws.
      </p>
      <h2>1. What information do we collect?</h2>
      <h3>Personal information you disclose to us</h3>
      <p>In short: we collect personal information that you provide to us.</p>
      <p>
        We collect personal information that you voluntarily provide to us when you register on the
        Services, express interest in obtaining information about us or our products and Services,
        when you participate in activities on the SErvice, or otherwise when you contact us.
      </p>
      <p>
        Personal Information Provided by You. The personal information that we collect depends on
        the context of your interactions with us and the Services, the choices you make, and the
        products and features you use. The personal information we may collection include the
        following:
        <ul>
          <li>Names</li>
          <li>Email addresses</li>
          <li>Usernames</li>
          <li>Passwords</li>
          <li>Billing addresses</li>
          <li>Mailing addresses</li>
          <li>Phone numbers</li>
        </ul>
      </p>
      <p>Sensitive information. We do not process sensitive information.</p>
      <p>
        All personal information that you provide to us must be true, complete, and accurate and you
        must notify us of any changes to such personal information.
      </p>
      <h2>2 How do we process your information?</h2>
      <p>
        In short: we process your information to provide, improve, and administer our Services,
        communicate with you, for security and fraud prevention, and to comply with the law. We may
        also process your information for other purposes with your consent.
      </p>

      <p>
        We process your personal information for a variety of reasons, depending on how you interact
        with our Services, including:
        <ul>
          <li>
            To facilitate account creation and authentication and otherwise manage user accounts. We
            may process your information so you can create and log in to your account, s well as
            keep your account in working order.
          </li>
          <li>
            To deliver and facility delivery of services to the user. We may process your
            information to provide you with the requested service.
          </li>
          <li>
            To respond to user inquiries/offer support to users. We may process your information to
            respond to your inquiries and solve any potential issues you might have with the
            requested service.
          </li>
          <li>
            To comply with our legal obligations. We may process your information to comply with our
            legal obligations, respond to legal requests, and exercise, establish, or defined our
            legal rights.
          </li>
        </ul>
      </p>
      <h2>3. When and with whom do we share your personal information?</h2>
      <p>
        In short: we may share information in specific situations described in this section and/or
        with the following third parties.
      </p>
      <p>
        We may need to share your personal information in the following situations:
        <ul>
          <li>
            Business Transfers. We may share or transfer your information in connection with, or
            during negotiations of, any merger, sale of company assets, financing, or acquisition of
            all or a portion of our business to another company
          </li>
        </ul>
      </p>
      <h2>4. How long do we keep your information?</h2>
      <p>
        In short:We keep your information for as long as necessary to fulfill the purposes outlined
        in this privacy notice unless otherwise required by law.
      </p>
      <p>
        We will only keep your personal information for as long as it is necessary for the purposes
        set out in this privacy notice, unless a longer retention period is required or permitted by
        law (such as tax, accounting, or other legal requirements). No purpose in this notice will
        require us keeping your personal information for longer than the period of time in which
        users have an account with us.
      </p>
      <p>
        When we have no ongoing legitimate business need to process your personal information, we
        will either delete or anonymize such information, or, if this is not possible (for example,
        because your personal information has been stored in backup archives), then we will securely
        store your personal information and isolate it from any further processing until deletion is
        possible.
      </p>
      <h2>5. How do we keep your information safe?</h2>
      <p>
        In short: We aim to protect your personal information through a system of organization and
        technical security measures.
      </p>
      <p>
        We have implemented appropriate and reasonable technical and organizational security
        measures designed to protect the security of any personal information we process. However,
        despite our safeguards and efforts to secure your information, no electronic transmission
        over the Internet or information storage technology can be guaranteed to be 100% secure, so
        we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third
        parties will not be able to defeat our security and improperly collect, access, steal, or
        modify your information. Although we will do our best to protect your personal information,
        transmission of personal information to and from our Services is at your own risk. You
        should only access the Services within a secure environment.
      </p>
      <h2>6. Do we collect information from minors?</h2>
      <p>
        In short: We do not knowingly collect data from or market to children under 18 years of age.
      </p>
      <p>
        We do not knowingly solicit data from or market to children under 18 years of age. By using
        the Services, you represent that you are at least 18 or that you are the parent or guardian
        of such a minor and consent to such minor dependent’s use of the Services. If we learn that
        personal information from users less than 18 years of age has been collected, we will
        deactivate the account and take reasonable measures to promptly delete such data from our
        records. If you become aware of any data we may have collected from children under age 18,
        please contact us at support@mozaicid.com
      </p>
      <h2>7. What are your privacy rights?</h2>
      <p>In short: You may review, change, or terminate your account at any time.</p>
      <p>
        If you are located in the EEA or UK and you believe we are unlawfully processing your
        personal information, you also have the right to complain to your local data protection
        supervisory authority. You can find their contact details here:
        https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
      </p>
      <p>
        If you are located in Switzerland, the contact details for the data protection authorities
        are available here: https://www.edoeb.admin.ch/edoeb/en/home.html
      </p>
      <p>
        Withdrawing your consent: If we are relying on your consent to process your personal
        information, which may be express and/or implied consent depending on the applicable law,
        you have the right to withdraw your consent at any time. You can withdraw your consent at
        any time by contacting us by using the contact details provided in the section HOW CAN YOU
        CONTACT US ABOUT THIS NOTICE? Below.
      </p>
      <p>
        However, please note that this will not affect the lawfulness of the processing before its
        withdrawal nor, when applicable law allows, will it affect the processing of your personal
        information conducted in reliance on lawful processing grounds other than consent.
      </p>
      <h3>Account Information</h3>
      <p>
        If you would at any time like to review or change the information in your account or
        terminate your account, you can:
        <ul>
          <li>Log in to your account settings and update your user account.</li>
          <li>Contact us using the contact information provided</li>
        </ul>
      </p>
      <p>
        Upon your request to terminate your account, we will deactivate or delete your account and
        information from our active databases. However, we may retain some information in our files
        to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal
        terms and/or comply with applicable legal requirements.
      </p>
      <p>
        If you have any questions or comments about your privacy rights, you may email us at
        support@mozaicid.com
      </p>
      <h2>8. Controls for Do-Not-Track Features</h2>
      <p>
        Most web browsers and some mobile operating systems and mobile applications include a
        Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference
        not to have data about your online browsing activities monitored and collected. At this
        stage no uniform technology standard for recognizing and implementing DNT signals has been
        finalized. As such, we do not currently respond to DNT browser signals or any other
        mechanism that automatically communicates your choice not to be tracked online. If a
        standard for online tracking is adopted that we must follow in the future, we will inform
        you about that practice in a revised version of this privacy notice.
      </p>
      <h2>9. Do California Residents Have Specific Privacy Rights?</h2>
      <p>
        In short: Yes, if you are a resident of California, you are granted specific rights
        regarding access to your personal information.
      </p>
      <p>
        California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our
        users who are California residents to request and obtain from us, once a year and free of
        charge, information about categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all third parties with
        which we shared personal information in the immediately preceding calendar year. If you are
        a California resident and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
      </p>
      <p>
        If you are under 18 years of age, reside in California, and have a registered account with
        Services, you have the right to request removal of unwanted data that you publicly post on
        the Services. To request removal of such data, please contact us using the contact
        information provided below and include the email address associated with your account and a
        statement that you reside in California. We will make sure the data is not publicly
        displayed on the Services, but please be aware that the data may not be completely or
        comprehensively removed from all our systems (e.g., backups, etc.).
      </p>
      <h3>CCPA Privacy Notice</h3>
      <p>
        The California Code of Regulations defines a “resident” as:
        <ul>
          <li>
            (1) every individual who is in the State of California for other than a temporary or
            transitory purpose
          </li>
          <li>
            (2) every individual who is domiciled in the State of California who is outside the
            State of California for a temporary or transitory purpose
          </li>
        </ul>
      </p>
      <p>All other individuals are defined as “non-residents.”</p>
      <p>
        If this definition of “resident” applies to you, we must adhere to certain rights and
        obligations regarding your personal information.
      </p>
      <p>What categories of personal information do we collect?</p>
      <p>
        We have collected the following categories of personal information in the past twelve (12)
        months:
      </p>
      <table style={{ border: '1px solid #333' }}>
        <thead>
          <tr>
            <td>Category</td>
            <td>Examples</td>
            <td>Collected</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>A. Identifiers </td>
            <td>
              Contact details such as real name, alias, postal address, telephone or mobile contact
              number, unique personal identifier, online identifier, Internet Protocol address,
              email address, and account name
            </td>
            <td> YES</td>
          </tr>
          <tr>
            <td>
              B. Personal information categories listed in the California Customer Records Statute
            </td>
            <td>
              Name, contact information, education, employment, employment history, and financial
              information
            </td>
            <td> YES</td>
          </tr>
          <tr>
            <td>C. Protected classification characteristics under California or federal law </td>
            <td>Gender and date of birth </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>D. Commercial information </td>
            <td>
              Transaction information, purchase history, financial details, and payment information
            </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>E. Biometric information </td>
            <td>Fingerprints and voiceprints </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>F. Internet or other similar network activity </td>
            <td>
              Browsing history, search history, online behavior, interest data, and interactions
              with our and other websites, applications, systems, and advertisements
            </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>G. Geolocation data </td>
            <td>Device location </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>H. Audio, electronic, visual, thermal, olfactory, or similar information </td>
            <td>
              Images and audio, video or call recordings created in connection with our business
              activities
            </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>I. Professional or employment-related information </td>
            <td>
              Business contact details in order to provide you our Services at a business level or
              job title, work history, and professional qualifications if you apply for a job with
              us
            </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>J. Education information </td>
            <td>Student records and directory information </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>K. Inferences drawn from other personal information </td>
            <td>
              Inferences drawn from any of the collected personal information listed above to create
              a profile or summary about, for example, an individual’s preferences and
              characteristics
            </td>
            <td>NO</td>
          </tr>
        </tbody>
      </table>
      <p>
        We may also collect other personal information outside of these categories through instances
        where you interact with us in person, online, or by phone or mail in the context of:
        <ul>
          <li>Receiving help through our customer support channels;</li>
          <li>Participate in customer surveys or contests; and</li>
          <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
        </ul>
      </p>
      <h3>How do we use and share your personal information?</h3>
      <p>
        More information about our data collection and sharing practices can be found in this
        privacy notice.
      </p>
      <p>
        You may contact us by visiting https://portal.mozaicid.com/support or by referring to the
        contact details at the bottom of this document.
      </p>
      <p>
        If you are using an authorized agent to exercise your right to opt out we may deny a request
        if the authorized agent does not submit proof that they have been validly authorized to act
        on your behalf.
      </p>
      <p>Will your information be shared with anyone else?</p>
      <p>
        We may disclose your personal information with our service providers pursuant to a written
        contract between us and each service provider. Each service provider is a for-profit entity
        that processes the information on our behalf.
      </p>
      <p>
        We may use your personal information for our own business purposes, such as for undertaking
        internal research for technological development and demonstration. This is not considered to
        be “selling” your personal information.
      </p>
      <p>
        Mozaic Inc. has not disclosed or sold any personal information to third parties for a
        business or commercial purpose in the preceding twelve (12) months. Mozaic Inc. will not
        sell personal information in the future belonging to website visitors, users, and other
        consumers.
      </p>
      <p>Your rights with respect to your personal data</p>
      <p>Right to request deletion of the data - Request to delete</p>
      <p>
        You can ask for the deletion of your personal information. If you ask us to delete your
        personal information, we will respect your request and delete your personal information,
        subject to certain exceptions provided by law, such as (but not limited to) the exercise by
        another consumer of his or her right to free speech, our compliance requirements resulting
        from a legal obligation, or any processing that may be required to protect against illegal
        activities.
      </p>
      <p>Right to be informed - Request to know</p>
      <p>
        Depending on the circumstances, you have a right to know:
        <ul>
          <li>Whether we collect and use your personal information;</li>
          <li>The categories of personal information that we collect;</li>
          <li>The purposes for which the collected personal information is used;</li>
          <li>Whether we sell your personal information to third parties;</li>
          <li>
            The categories of personal information that we sold or disclosed for a business purpose;
          </li>
          <li>
            The categories of third parties to whom the personal information was sold or disclosed
            for a business purpose; and
          </li>
          <li>
            Their business or commercial purpose for collection or selling personal information.
          </li>
        </ul>
      </p>
      <p>
        In accordance with applicable law, we are not obligated to provide or delete consumer
        information that is de-identified in response to a consumer request or to re-identify
        individual data to verify a consumer request.
      </p>
      <p>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights</p>
      <p>We will not discriminate against you if you exercise your privacy rights.</p>
      <h3>Verification process</h3>
      <p>
        Upon receiving your request, we will need to verify your identity to determine you are the
        same person about whom we have the information in our system. These verification efforts
        require us to ask you to provide information so that we can match it with information you
        have previously provided us. For instance, depending on the type of request you submit, we
        may ask you to provide certain information so that we can match the information you provide
        with the information we already have on file, or we may contact you through a communication
        method (e.g., phone or email) that you have previously provided to us. We may also use other
        verification methods as the circumstances dictate.
      </p>
      <p>
        We will only use personal information provided in your request to verify your identity or
        authority to make the request. To the extent possible, we will avoid requesting additional
        information from you for the purposes of verification. However, if we cannot verify your
        identity from the information already maintained by us, we may request that you provide
        additional information for the purposes of verifying your identity and for security or
        fraud-prevention purposes. We will delete such additionally provided information as soon as
        we finish verifying you.
      </p>
      <p>
        Other privacy rights
        <ul>
          <li>You may object to the processing of your personal information.</li>
          <li>
            You may request correction of your personal data if it is incorrect or no longer
            relevant, or ask to restrict the processing of the information.
          </li>
          <li>
            You can designate an authorized agent to make a request under the CCPA on your behalf.
            We may deny a request from an authorized agent that does not submit proof that they have
            been validly authorized to act on your behalf in accordance with the CCPA.
          </li>
          <li>
            You may request to opt out from future selling of your personal information to third
            parties. Upon receiving an opt-out request, we will act upon the request as soon as
            feasibly possible, but no later than fifteen (15) days from the date of the request
            submission.
          </li>
        </ul>
      </p>
      <p>
        To exercise these rights, you can contact us by visiting https://portal.mozaicid.com/support
        or by referring to the contact details at the bottom of this document. If you have a
        complaint about how we handle your data, we would like to hear from you.
      </p>
      <h2>10. Do we make updates to this notice?</h2>
      <p>
        In short: Yes, we will update this notice as necessary to stay compliant with relevant laws.
      </p>
      <p>
        We may update this privacy notice from time to time. The updated version will be indicated
        by an updated "Revised" date and the updated version will be effective as soon as it is
        accessible. If we make material changes to this privacy notice, we may notify you either by
        prominently posting a notice of such changes or by directly sending you a notification. We
        encourage you to review this privacy notice frequently to be informed of how we are
        protecting your information.
      </p>
      <h2>11. How can you contact us about this notice?</h2>
      <p>
        If you have questions or comments about this notice, you may email us at
        support@mozaicid.com or by post to:
        <ul style={{ listStyle: 'none', paddingTop: '5px' }}>
          <li>Mozaic Inc.</li>
          <li>Chesapeake, VA</li>
          <li>United States</li>
        </ul>
      </p>
    </div>
  );
}

export default PrivacyPolicy;
