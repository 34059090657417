import { MFAType, VisibleForm } from './types';

type State = {
  /** the user's email address */
  email: string;
  /** the MFA type to be used */
  mfaType: MFAType;
  /** the Cognito user object of the person attempting to log in */
  user: any;
  /** the form that should be visible to the user */
  visibleForm: VisibleForm;
};

type Action =
  | { type: 'showForgotPasswordForm'; email: string }
  | { type: 'showLoginForm' }
  | { type: 'showMFAForm'; email: string; mfaType: MFAType; user: any }
  | { type: 'showNewAccount'; email: string }
  | { type: 'showPasswordForm'; email: string; user: any };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'showForgotPasswordForm':
      return {
        ...state,
        email: action.email,
        visibleForm: VisibleForm.ForgotPassword
      };
    case 'showLoginForm':
      return {
        ...state,
        email: '',
        mfaType: null,
        user: null,
        visibleForm: VisibleForm.Login
      };
    case 'showMFAForm':
      return {
        ...state,
        email: action.email,
        mfaType: action.mfaType,
        user: action.user,
        visibleForm: VisibleForm.MultiFactorAuth
      };
    case 'showNewAccount':
      return {
        ...state,
        email: action.email,
        mfaType: null,
        user: null,
        visibleForm: VisibleForm.NewAccount
      };
    case 'showPasswordForm':
      return {
        ...state,
        email: action.email,
        user: action.user,
        visibleForm: VisibleForm.NewPassword
      };
    /* istanbul ignore next */
    default:
      return state;
  }
}

export function initialize(): State {
  return {
    email: '',
    mfaType: null,
    user: '',
    visibleForm: VisibleForm.Login
  };
}

export default reducer;
