import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export type Props = {
  /** the FontAwesome icon to be displayed */
  icon?: IconProp;
  /** true if this item is part of a submenu */
  submenu?: boolean;
  /** the text to display next to the icon */
  title: string;
  /** the URL the link should point to */
  to: string;
};

function SidebarItem({ icon, submenu = false, title, to }: Props) {
  return (
    <li>
      <NavLink to={to}>
        {icon && <FontAwesomeIcon icon={icon} style={{ marginRight: '10px', fontSize: '17px' }} />}
        <span className={!submenu ? 'title' : ''}>{title}</span>
      </NavLink>
    </li>
  );
}

export default SidebarItem;
