import { useState } from 'react';
import { Form, Formik } from 'formik';
import Alert from 'react-bootstrap/lib/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import TextInput from '../../../shared/Form/TextInput';
import styles from '../Login.module.css';
import ResetPassword from '../ResetPassword';
import CreateAccount from '../CreateAccount';
import * as Yup from 'yup';
import { Auth } from 'aws-amplify';
import { LoginSuccessFunc, MFAType } from '../types';
import LoadingButton from '../../../shared/Buttons/Loading';

type Props = {
  /** the function to call when the user has forgotten their password */
  onForgotPassword: (email: string) => void;
  /** the function to call if the Cognito login fails with an unknown user */
  onLegacyLogin: (username: string, password: string) => Promise<any>;
  /** the function to call if the user needs to provide a MFA token */
  onMFA: (user: any, mfaType: MFAType, email: string) => void;
  /** the function to call when a user creates a brand new account */
  onNewAccount: (email: string) => void;
  /** the function to call if the user needs to change their password */
  onNewPassword: (user: any, email: string) => void;
  /** the function to call if the user has successfully logged in */
  onSuccess: LoginSuccessFunc;
};

function LoginForm({
  onForgotPassword,
  onLegacyLogin,
  onMFA,
  onNewAccount,
  onNewPassword,
  onSuccess
}: Props) {
  const [busy, setBusy] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const initialValues = {
    username: '',
    password: ''
  };
  const schema = Yup.object().shape({
    username: Yup.string().required('Please enter your email address'),
    password: Yup.string().required('Please enter your password')
  });

  const handleSubmit = (values: any) => {
    setBusy(true);
    Auth.signIn(values.username, values.password)
      .then((user) => {
        // Possible (self explanatory) challenge values we care about: SMS_MFA, SOFTWARE_TOKEN_MFA,
        // or NEW_PASSWORD_REQUIRED.
        switch (user.challengeName) {
          case 'NEW_PASSWORD_REQUIRED':
            setBusy(false);
            onNewPassword(user, values.username);
            break;
          case 'SMS_MFA':
          case 'SOFTWARE_TOKEN_MFA':
            setBusy(false);
            onMFA(user, user.challengeName, values.username);
            break;
          default:
            const attributes = user.attributes || user.signInUserSession.idToken.payload;
            return onSuccess(
              user.username,
              user.signInUserSession.accessToken.jwtToken,
              attributes.email,
              attributes.given_name,
              attributes.family_name
            );
        }
      })
      .catch((err) => {
        if (err.code === 'UserNotFoundException') {
          onLegacyLogin(values.username, values.password).catch((err: Error) => {
            setErrorMessage(err.message);
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            setBusy(false);
          });
        } else if (err.code === 'UserNotConfirmedException') {
          onNewAccount(values.username);
        } else if (err.code === 'PasswordResetRequiredException') {
          setBusy(false);
          onForgotPassword(values.username);
        } else {
          setBusy(false);
          setErrorMessage(err.message);
        }
      });
  };

  return (
    <Formik
      className="form-auth-small"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ values }) => (
        <Form>
          {errorMessage && (
            <Alert bsStyle="danger" data-testid="login-message">
              <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '15px' }} />
              {errorMessage}
            </Alert>
          )}
          <TextInput autoFocus name="username" type="email" placeholder="user@domain.com" />
          <TextInput name="password" type="password" autoComplete="off" placeholder="Password" />
          <div style={{ marginTop: '20px' }}>
            <LoadingButton block bsSize="large" disabled={busy}>
              LOGIN
            </LoadingButton>
          </div>
          <div className={styles.subMenu}>
            <ResetPassword
              onError={setErrorMessage}
              onSuccess={() => onForgotPassword(values.username)}
              username={values.username}
            />
            <span>|</span>
            <CreateAccount onSuccess={onNewAccount} />
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default LoginForm;
