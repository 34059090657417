import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import Alert from 'react-bootstrap/lib/Alert';

type Props = {
  /** the child elements to display within this wrapper */
  children: any;
  /** the CSS class name to apply to the outer wrapper */
  className: string;
  /** any errors to display in lieu of the chilren */
  errors: string[];
};

function DisplayErrorsWrapper({ children, className, errors }: Props) {
  return errors.length > 0 ? (
    <div className={className}>
      <Alert bsStyle="danger">
        <h4>
          <FontAwesomeIcon icon={faExclamationTriangle} /> One or more errors occurred.
        </h4>
        {errors.map((error) => (
          <p key={error}>{error}</p>
        ))}
      </Alert>
    </div>
  ) : (
    children
  );
}

export default DisplayErrorsWrapper;
