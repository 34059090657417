import { useState } from 'react';
import { Form, Formik } from 'formik';
import Alert from 'react-bootstrap/lib/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import TextInput from '../../../shared/Form/TextInput';
import Button from 'react-bootstrap/lib/Button';
import * as Yup from 'yup';
import { Auth } from 'aws-amplify';
import { LoginSuccessFunc } from '../types';

type Props = {
  /** the user's email address */
  email: string;
  /** the function to call when the password change is successful */
  onSuccess: LoginSuccessFunc;
  /** the Cognito user that requires the password change */
  user: any;
};

function NewPasswordForm({ email, onSuccess, user }: Props) {
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const initialValues = {
    password1: '',
    password2: ''
  };
  const schema = Yup.object().shape({
    password1: Yup.string().required('A password is required'),
    password2: Yup.string().oneOf([Yup.ref('password1'), null], 'Passwords do not match')
  });

  const handleSubmit = ({ password1 }: any) => {
    setLoading(true);
    Auth.completeNewPassword(user, password1)
      .then((response) =>
        onSuccess(
          response.username,
          response.signInUserSession.accessToken.jwtToken,
          email,
          response.attributes.given_name,
          response.attributes.family_name
        )
      )
      .catch((err) => {
        setErrorMessage(err.message);
        setLoading(false);
      });
  };

  return (
    <Formik
      className="form-auth-small"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      <Form>
        {!errorMessage && <Alert bsStyle="info">Please enter a new password</Alert>}
        {errorMessage && (
          <Alert bsStyle="danger" data-testid="login-message">
            <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '15px' }} />
            {errorMessage}
          </Alert>
        )}
        <TextInput
          name="password1"
          type="password"
          autoComplete="off"
          placeholder="Enter a new password"
        />
        <TextInput
          name="password2"
          type="password"
          autoComplete="off"
          placeholder="Re-type your new password"
        />
        <div style={{ marginTop: '20px' }}>
          <Button block bsSize="large" bsStyle="primary" disabled={loading} type="submit">
            SET PASSWORD
          </Button>
        </div>
      </Form>
    </Formik>
  );
}

export default NewPasswordForm;
