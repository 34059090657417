import styles from './Loader.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';

function Loader() {
  return (
    <div className={styles.Loader}>
      <span>
        <FontAwesomeIcon icon={faSpinner} size="3x" spin />
        <span className={styles.label}>Loading...</span>
      </span>
    </div>
  );
}

export default Loader;
