import { useCallback } from 'react';
import { ApolloError, gql, useMutation } from '@apollo/client';
import { CustomerModalOutput, CustomerType } from '../../types';

type UseCreateCustomerType = {
  /** the response from the mutate function */
  data?: CustomerType;
  /** any errors produced by Apollo */
  error?: ApolloError;
  /** true if the server is processing the request */
  loading: boolean;
  /** the function to call to initiate the update process */
  mutate: (customer: CustomerModalOutput) => Promise<any>;
};

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($fields: CreateCustomerFieldsInput) {
    createCustomer(input: { fields: $fields }) {
      clientMutationId
      customer {
        active
        id
        licenses
        name
        portal_renewal
      }
    }
  }
`;

/**
 * This function will add a new customer in the database and make sure it gets added into the cache.
 */
function useCreateCustomer(): UseCreateCustomerType {
  const [mutate, { data, error, loading }] = useMutation(CREATE_CUSTOMER);

  const createCustomer = useCallback(
    (value: CustomerModalOutput) => {
      return mutate({
        variables: {
          fields: {
            active: value.active,
            groups: value.groups,
            licenses: value.licenses,
            name: value.name,
            ...(value.portal_renewal ? { portal_renewal: value.portal_renewal } : {}),
            ...(value.parent ? { parent: { link: value.parent } } : {})
          }
        }
      });
    },
    [mutate]
  );

  return { data, error, loading, mutate: createCustomer };
}

export default useCreateCustomer;
